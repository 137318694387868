<template>
  <div class="content">
    <!-- 加密打印新手引导 -->
    <div class="main">
      <p class="title">打印加密订单新手引导</p>
      <div class="box_set">
        <div class="box">
          <span class="channel" style="">快手加密订单设置</span>
          <span class="tip">打印快手加密订单需完成设置</span>
          <el-button class="btn" type="warning" round size="mini" @click="open_page('',1)">去设置</el-button>
        </div>
        <div class="box" style="margin:0px 20px;">
          <span class="channel" style="">抖音加密订单设置</span>
          <span class="tip">打印抖音加密订单需完成设置</span>
          <el-button @click="open_page('',2)" class="btn" type="warning" style="" round size="mini">去设置</el-button>
        </div>
        <div class="box">
          <span class="channel" style="">淘宝加密订单设置</span>
          <span class="tip">打印淘宝加密订单需完成设置</span>
          <el-button @click="open_page(TaobaoAuthURL)" class="btn" type="warning" style="" round size="mini">去设置</el-button>
        </div>
      </div>
      <div style="margin-top:52px;cursor: pointer;">
        <img :src="OSS+('/contact_wang.png')" alt="">
        <span @click="contactWang" style="margin-left:10px;color:#3287EB;vertical-align: super;font-size: 16px;">联系旺旺</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      TaobaoAuthURL: (state)=> state.batchprint.TaobaoAuthURL,
    }),
    showEncryption: {
        get() {
          return this.$store.state.encryption.encryptionDialog
        },
        set(val) {
          return this.$store.commit('encryption/SAVE',{encryptionDialog:val})
        },
      },
  },
  data() {
    return {
      
    }
  },
  methods: {
    open_page(url,type){
      if(sessionStorage.getItem('edition')=='0'){
        this.showEncryption=true
      }else{
        if(type){
          let routeUrl=''
          if(type==1){
            routeUrl = this.$router.resolve({
            path: "/guide/kuaishou",
          });
          }
          if(type==2){
            // window.open('https://www.yuque.com/nanxi-tbdiz/gbcosn/fc2gvr3nva03fks7?singleDoc#')
            // 抖音加密代码
            routeUrl = this.$router.resolve({
            path: "/guide/douyin",
            });
          }
          let href = window.location.origin + window.location.pathname + window.location.search + '#' + routeUrl.route.fullPath
          window.open(href , "_blank");
        }else{
          window.open(url)
        }
      }
    },
   
    // 联系旺旺
    contactWang(){
      window.open("https://amos.alicdn.com/getcid.aw?spm=a261y.7663282.autotrace-topbar.154.4e6e66b9A4r8FI&v=3&groupid=0&s=1&charset=utf-8&uid=%E5%85%B4%E4%B8%9A%E5%AE%9D%E4%BA%91%E4%BB%93&site=cnalichn");
    }
  },
}
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  text-align: center;
  margin-top: 90px;
  .main {
    margin: 0 auto;
    .title {
      font-size:20px;
      font-weight:600;
    }
    .box_set {
      width: 700px;
      margin: 0 auto;
      display: flex;
      margin-top: 36px;
    }
    .box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 214px;
      height: 160px;
      border-radius: 8px;
      box-shadow: 0px 3px 9px rgba(208, 208, 208, 0.4);
      .channel{
        font-size: 16px;
        font-weight: 600;
      }
      .tip{
        color: #64676B;
        margin: 18px 0px 24px;
      }
      .btn{
        width:114px;
        height: 36px;
        border-color: #F6891E;
        background-color: #FE773F;
        color:#FFF;
        font-size: 14px;
      }
    }
  }

}

</style>