<template>
    <div>
        <div class="mask" v-if="showEncryption">
        <!-- 加密打印 -->
        <div style="text-align:right;">
            <span class="close" @click="clickCancel">×</span>
            <div class="content">
                <!-- :src="OSS+('/diamonds.png')"  -->
                <img class="diamonds" :src="OSS+('/diamonds.png')" alt="">
                <!-- 标题 -->
                <div class="title">
                    <span class="title_fr">该功能升级<span style="color:#E21B1C;">高级版</span>后可用</span>
                    <span class="title_se">当前版本为初级版本，升级版本后享受</span>
                </div>
                <!-- 版本 -->
                <div class="edition">
                    <div class="edition_l edition_all">
                        <p>初级版本</p>
                        <div class="edition_list">
                            <div v-for="(item,index) in editionData" :key="index">
                                <span v-if="item.symbol=='check'" style="font-size: 14px; color: #00A869;font-weight: 600;margin-right:10px;">✓</span>
                                <span v-if="item.symbol=='exclamation'" style="font-size: 8px; color: #00A869;font-weight: 600;margin-right:10px;">
                                <img :src="OSS+('/exclamatory_mark.png')" alt="">
                                </span>
                                <span :style="{'color':item.symbol=='exclamation'?' #878C98':'black'}">{{ item.title }}</span>    
                            </div>
                        </div>
                    </div>
                    <div class="edition_r edition_all">
                        <p style="color:#610406;">高级版本<span class="proposal">荐</span></p>
                        <div class="edition_list" style="font-weight:600;">
                            <span v-for="(item,index) in editionData" :key="index">
                                <span style="font-size: 14px; color: #00A869;font-weight: 600;margin-right:10px;">✓</span>{{ item.title }}</span>
                        </div>
                    </div>
                </div>
                <!-- 升级高级版本 -->
                <div class="upgrade" @click="upgradeBtn">升级高级版本</div>
            </div>
        </div>
    </div>
    <selfPopup v-if="auth_popup" text="您是否完成私密打-高级版订购？"
        confirmText="已完成" cancelText="未完成" icon="iconfont icon-bangzhu1"
        @confirm="auth_popup_confirm"
        @cancel="auth_popup_cancel"
        >
      </selfPopup>
    </div>
</template>

<script>
import selfPopup from "@/components/self_popup";
export default {
    name: 'encryptionDialog',
    components:{selfPopup},
    data() {
        return {
            editionData:[
                {id:1,title:'批量打印',symbol:'check'},
                {id:2,title:'快递模板设置',symbol:'check'},
                {id:3,title:'打印1688订单',symbol:'check'},
                {id:4,title:'打印淘宝加密订单',symbol:'exclamation'},
                {id:5,title:'打印快手加密订单',symbol:'exclamation'},
                {id:6,title:'打印抖音加密订单',symbol:'exclamation'},
            ],
            auth_popup:false
        }
    },
    computed:{
        showEncryption:{
            get(){
                return this.$store.state.encryption.encryptionDialog
            },
            set(val){
                return this.$store.commit('encryption/SAVE',{encryptionDialog:val})
            }
        }
    },
    methods: {
        clickCancel(){
            this.$emit("clickCancel")
            this.showEncryption=false;
        },
        upgradeBtn(){
            window.open('http://ays.cn/N0r')
            this.showEncryption=false
            this.auth_popup=true
        },
        
        auth_popup_confirm(){
            location.reload()
        },
        auth_popup_cancel(){
            this.auth_popup=false
            this.showEncryption=true
        }
    },
    
}
</script>

<style lang="less" scoped>
 .mask{
    background-color: rgba( #000, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    // z-index: 999999;
    z-index: 2001;
    }
    .close {
        color:#fff;
        font-size:20px;
        cursor: pointer;
        font-size:35px;
        font-weight: 100;
    }
    .content {
        position: relative;
        width: 391px;
        height: 495px;
        background-color: transparent;
        background: url('https://cdnprinttool.xingyebao.com/1688PrintTool/images/sdvanced_version.png') no-repeat;
        padding: 0px 16px;
        padding-top: 25px;
        box-sizing: border-box;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        .diamonds{
            position: absolute;
            top: -10px;
            right: 18px;
        }
        .title {
            text-align: left;
            .title_fr {
            font-size: 21px;
            color: #680305;
            display: block;
            font-weight: 600;
            margin-bottom: 8px;
            }
            .title_se {
            font-size: 13px;
            color: #450301;
            }
        }
        
        .edition{
            text-align: center;
            margin-top: 18px;
            display: flex;
            justify-content: space-between;
            p {
                margin-top: 14px;
                font-weight: 600;
                height: 22px;
                line-height: 22px;
            }
            .edition_all {
                width: 173px;
                height: 313px;
                display: flex;
                flex-direction: column;
            }
            .edition_list {
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                    flex: 1;
                    justify-content: space-between;
                    padding: 20px 0px 24px 20px;
                    box-sizing: border-box;
                    font-size: 14px;
                }
            .edition_l {
                background: url("https://cdnprinttool.xingyebao.com/1688PrintTool/images/primary_bg.png") no-repeat;
                
            }
            .edition_r {
                background: url('https://cdnprinttool.xingyebao.com/1688PrintTool/images/senior_bg.png') no-repeat;
                .proposal {
                    width: 18px;
                    height: 18px;
                    background: linear-gradient(137.82deg, #FF6840 8.2%, #FD4E4C 93.12%);
                    border-radius: 6px;
                    color: #fff;
                    font-size: 12px;
                    display: inline-block;
                    margin-left: 2px;
                    line-height: 18px;
                }
            }
        }
        .upgrade {
            width: 358px;
            height: 54px;
            background-color: #FF5958;
            border-radius: 12px;
            margin-top: 14px;
            text-align: center;
            line-height: 54px;
            color: #fff;
            font-weight: 600;
            cursor: pointer;
        }
      
}
    

</style>