<template src="./index.html"></template>
<style src="./index.less" lang="less" scoped></style>

<script>
import { mapState } from "vuex";
import topMenu from "@/components/top-menu";
import mySearchForm from "@/UI/mySearchForm";
import progressBarPopup from "@/UI/progressBarPopup";
import index from "@/components/batchprint/index";
import ResultList from "@/components/batchprint/ResultList/index.vue";
import encryptionNewUser from "@/components/newUser/encryption";
import encryptionDialog from "@/components/appDialog/encryptionDialog";

export default {
  components: {
    mySearchForm,
    index,
    ResultList,
    progressBarPopup,
    topMenu,
    encryptionNewUser,
    encryptionDialog
  },
  data() {
    return {
      activeFahuoIndex: "0",
      // 发货单全局设置
      FahuoGlobalSettingsDialog: true,
      NewUserShow: false,

    };
  },
  mounted() {
    // 判断是否是高级版，0为初级版
    this.$store.dispatch("encryption/getAppOrder").then((res) => {
      if(sessionStorage.getItem('edition')=='0'){
        this.$store.commit('encryption/SAVE',{encryptionDialog:true})
      } else{
        this.NewUserShow=true
      }
    })
    this.searchFormData_temp = JSON.parse(JSON.stringify(this.searchFormData));
    this.$store.dispatch("batchprint/get_search_result",true);
    this.$store.dispatch("batchprint/GetTaobaoAuthURL");
    this.$store.dispatch("print/getFieldData")
    this.$store.commit("batchprint/SET_ISSHOW", "");
    this.$store.dispatch("setting/getConfig").then((res) => {
      localStorage.setItem('pageSize',res.data.config_info.pagesize)
      localStorage.setItem('autoMerge',res.data.config_info.auto_merge)
      localStorage.setItem('priorityMerge',res.data.config_info.priority_merge)
    })
  },
 
  computed: {
    ...mapState({
      isShow: (state) => state.batchprint.isShow,
      searchFormData: (state) => state.batchprint.searchFormData,
      fahuoTemplateList: (state) => state.print.fahuoTemplateList,
      // 打印参数
      printExpressTemplate: (state) => state.batchprint.printExpressTemplate,
      printExpressBgimg: (state) => state.batchprint.printExpressBgimg,
      printFahuoTemplate: (state) => state.batchprint.printFahuoTemplate,
      printFahuoBgimg: (state) => state.batchprint.printFahuoBgimg,
      progressBarPopup:(state) => state.batchprint.progressBarPopup,
      TaobaoAuthURL: (state)=> state.batchprint.TaobaoAuthURL,
      express_list: (state) => state.setting.express_list,
    }),
    addExpressId: {
      get() {
        return this.$store.state.print.addExpressId;
      },
      set(val) {
        this.$store.commit("print/SET_ADD_EXPRESS_ID", val);
      },
    },
    expressTemplate: {
      get() {
        return this.$store.state.batchprint.expressTemplate;
      },
      set(val) {
        this.$store.commit("batchprint/SET_EXPRESS_TEMPLATE", val+'');
      }
    },
  },
  methods: {
    // searchForm函数
    // 更新表单数据
    updateFormData(val) {
      this.searchFormData = val;
    },
    // 点击查询按钮
    submitSearch() {
      if(sessionStorage.getItem('edition')=='0'){
        this.$store.commit('encryption/SAVE',{encryptionDialog:true})
        this.NewUserShow = false
      }else{
        this.searchFormData.search_type=2
        this.$store.commit("batchprint/SAVE",{is_shipped:0})
        this.searchFormData.pull_data = 1 // 标识是否拉取新数据
        this.$store.dispatch("batchprint/get_search_result").then(res=>{
          this.searchFormData.pull_data = 0 // 使用完成后还原
        });
        this.$store.commit("batchprint/SET_ISSHOW", "ResultList");
        this.NewUserShow = false
      }
    },
    textJump() {
      let path = `${window.location.protocol}//${window.location.host}/#/usetutorial`
      window.open(path);
    },
    handleShowSet(){
      this.NewUserShow = true
    }
  }
};
</script>
